<template>
    <div>
        <router-link to="/e-commerce/heighlites/new">
            <vs-button
              class="
                btn-add-new
                p-3
                mb-4
                ml-4
                rounded-lg
                cursor-pointer
                flex
                items-center
                justify-center
              "
            >
              <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
              <span class="ml-2">إدراج جديد</span>
              
                          </vs-button>
          </router-link>
      <table class="table">
        <thead>
          <tr>
            <th>المنتج المتضمن</th>
            <th>رمز الـ sku</th>
            <th>ملاحظـة</th>
         
            <th>العمليات</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(heighlite, index) in heighlites.data.data" :key="index">
            <td>{{ heighlite.product.title }}</td>
            <td>{{ heighlite.product.sku }}</td>
            <td>{{ heighlite.note }}</td>
            <td>
              <button class="btn btn-danger" @click="deleteheighlite(heighlite.id)">Delete</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        heighlites: [],
      };
    },
    created() {
      this.getheighlites();
    },
    methods: {
      getheighlites() {
        axios.get('https://boutiquette-store.ly/Highlights')
          .then(response => {
            this.heighlites = response.data;
          })
          .catch(error => {
            null
          });
      },
      addheighlite() {
        // Code to add a new price range
      },
      editheighlite(heighlite) {
        // Code to edit a price range
      },
      deleteheighlite(id) {
        axios.delete(`https://boutiquette-store.ly/price-ranges/${id}`)
          .then(response => {
            this.getheighlites();
          })
          .catch(error => {
            null
          });
      },
    },
  };
  </script>
  